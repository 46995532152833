import { defineComponent } from 'vue';
export default defineComponent({
    name: 'DownloadPage',
    methods: {
        generateQRCode(url) {
            // 这里使用了一个假设的QR码生成服务
            // 在实际应用中，您需要替换为真实的QR码生成服务URL
            return `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${encodeURIComponent(url)}`;
        },
    },
});
