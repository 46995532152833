import { defineComponent, ref, onMounted } from 'vue';
import { Message } from '@arco-design/web-vue';
import { checkCacheAndPreload, monitorNetworkRequests, reportCacheUsage, } from '@/utils/preloader';
export default defineComponent({
    name: 'HomePage',
    setup() {
        const isLoading = ref(false);
        const isPreloaded = ref(false);
        const showLoadingModal = ref(false);
        const loadingProgress = ref(0);
        const preloadFlutterApp = async () => {
            if (localStorage.getItem('flutterAppPreloaded')) {
                isPreloaded.value = true;
                return;
            }
            try {
                showLoadingModal.value = true;
                isLoading.value = true;
                monitorNetworkRequests();
                await checkCacheAndPreload((progress) => {
                    loadingProgress.value = progress;
                    console.log(`Preload progress: ${progress}%`);
                });
                reportCacheUsage();
                isPreloaded.value = true;
                localStorage.setItem('flutterAppPreloaded', 'true');
                Message.success('App resources preloaded successfully');
            }
            catch (error) {
                console.error('Failed to preload App:', error);
                Message.error('Failed to preload App resources');
            }
            finally {
                showLoadingModal.value = false;
                isLoading.value = false;
            }
        };
        const handleNavigate = () => {
            // if (!isPreloaded.value) {
            //   Message.warning('Please wait for the App to finish preloading')
            //   return
            // }
            // window.location.replace('/web/index.html')
        };
        onMounted(() => {
            preloadFlutterApp();
        });
        return {
            handleNavigate,
            isLoading,
            isPreloaded,
            showLoadingModal,
            loadingProgress,
        };
    },
});
