// Script 部分保持不变
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { IconDown } from '@arco-design/web-vue/es/icon';
export default defineComponent({
    name: 'Header',
    components: {
        IconDown,
    },
    setup() {
        const route = useRoute();
        const { t, locale } = useI18n();
        const currentRoute = computed(() => route.name);
        const currentLanguage = computed(() => locale.value === 'zh' ? '简体中文' : 'English');
        const changeLanguage = (value) => {
            locale.value = value;
        };
        return {
            currentRoute,
            currentLanguage,
            changeLanguage,
        };
    },
});
