import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../components/HomePage.vue';
import PricePage from '../components/PricePage.vue';
import DownloadPage from '../components/DownloadPage.vue';
const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomePage,
    },
    {
        path: '/pricing',
        name: 'Price',
        component: PricePage,
    },
    {
        path: '/download',
        name: 'Download',
        component: DownloadPage,
    },
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
export default router;
