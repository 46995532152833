import { createI18n } from 'vue-i18n';
const messages = {
    en: {
        header: {
            home: 'Home',
            pricing: 'Pricing',
            download: 'App Download',
            login: 'Login/Register',
        },
        home: {
            hero: {
                title: 'Professional Voice Transcription Assistant, Powered by AI',
                subtitle: 'Automatically transcribe your meetings, interviews, lectures, and media content. Fast, accurate, and affordable.',
                cta: 'Try for Free',
                freeTrial: 'Includes 120 minutes of free transcription',
            },
            features: {
                title: 'Features',
                card1: {
                    title: 'Transcribe Audio Files',
                    description: 'Support for multiple audio formats, quickly and accurately transcribe audio files.',
                },
                card2: {
                    title: 'Transcribe Online Meetings',
                    description: 'Transcribe Zoom, Google Meet, and Microsoft Teams online meetings with AI meeting assistant.',
                },
                card3: {
                    title: 'Smart Editor',
                    description: 'Easily proofread and edit transcribed content with the smart text editor.',
                },
                card4: {
                    title: 'Rich Export Options',
                    description: 'Export transcribed text content to multiple file formats (TXT, SRT, Word, etc.).',
                },
            },
            services: {
                title: 'Most Accurate Voice Transcription Service',
                description: 'Our voice transcription service uses state-of-the-art speech recognition technology to ensure accuracy and speed. Suitable for meetings, lectures, interviews, and more.',
            },
        },
    },
    zh: {
        header: {
            home: '首页',
            pricing: '价格',
            download: 'App下载',
            login: '登录/注册',
        },
        home: {
            hero: {
                title: '专业的语音转录助手，由 AI 提供支持',
                subtitle: '自动转录您的会议、采访、讲座和媒体内容。快速、准确且经济实惠。',
                cta: '免费试用',
                freeTrial: '包括 120 分钟免费转录',
            },
            features: {
                title: '特征',
                card1: {
                    title: '转录音频文件',
                    description: '支持多种音频格式，快速准确地转录音频文件。',
                },
                card2: {
                    title: '转录在线会议',
                    description: '通过 AI 会议助手，将 Zoom、Google Meet 和 Microsoft Teams 在线会议转录。',
                },
                card3: {
                    title: '智能编辑器',
                    description: '使用智能文本编辑器，轻松校对和编辑转录内容。',
                },
                card4: {
                    title: '丰富的导出选项',
                    description: '将文字转录内容导出为多种文件格式（TXT、SRT、Word 等）。',
                },
            },
            services: {
                title: '最准确的语音转录服务',
                description: '我们的语音转录服务使用最先进的语音识别技术，确保准确性和速度。适用于会议、讲座、采访等多种场景。',
            },
        },
    },
};
const i18n = createI18n({
    legacy: false,
    locale: 'zh',
    fallbackLocale: 'en',
    messages,
});
export default i18n;
